<script setup>
import { ref, defineProps } from "vue";
import AppButton from "@/components/AppButton";
import AppCard from "@/components/AppCard";

const props = defineProps({
  user: {
    type: Object,
    required: true
  }
});

const profile = ref({
  name: props.user?.name || '',
  email: props.user?.email || '', 
  razon_social: props.user?.razon_social || '',
  vat: props.user?.vat || '',
});

</script>

<template lang="pug">
app-card
    .user-info.space-y-4
        .info-group
            .text-sm.text-gray-500 Nombre
            .text-base.font-medium {{ profile.name || 'No especificado' }}
        
        .info-group
            .text-sm.text-gray-500 Email
            .text-base.font-medium {{ profile.email || 'No especificado' }}
        
        .info-group
            .text-sm.text-gray-500 Vat
            .text-base.font-medium {{ profile.vat || 'No especificado' }}
        
        .info-group
            .text-sm.text-gray-500 Razón Social
            .text-base.font-medium {{ profile.razon_social || 'No especificado' }}
</template>

<style scoped>
.card {
  max-width: 32rem;
  margin: 0 auto;
}
</style>
