<script setup>
import { ref, onMounted, watch, computed } from "vue";
import AppFormInput from "@/components/Form/AppFormInput";
import AppCheckbox from "@/components/Form/AppCheckbox";
import AppButton from "@/components/AppButton";
import CardUserProfile from "@/components/Profile/CardUserProfile.vue";
import { axios } from "@/utils/axios";
import { store } from "@/store";

const loading = ref(false);

const profile = computed(() => store.user);

onMounted(async () => {
  try {
    console.log("profile");
    store.APP_LOADING = true;
    // profile.value = store.user;
  } catch (error) {
    console.error(error);
  } finally {
    store.APP_LOADING = false;
  }
});


</script>
<template lang="pug">
div
  card-user-profile(:user="profile")
</template>


<style scoped>
.divider {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
